import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import { Box, Icon, Link, Badge } from '@qga/roo-ui/components';
import noop from 'lodash/noop';
import { Label, LabelText } from 'components/Label';
import TextHighlighter from 'components/TextHighlighter';
import ResponsiveModal from 'components/ResponsiveModal';
import RecentSearch from './RecentSearch';
import { useSelector, useDispatch } from 'react-redux';
import { useLocationData } from './useLocationData';
import {
  AutocompleteInput,
  TitleText,
  RecentSearchesWrapper,
  ResultItem,
  ResultList,
  TitleWrapper,
  ResultItemRecentSearch,
} from './primitives';
import { getSearchLocalStorage, clearSearchLocalStorage } from './../HomePage/localStorage';
import { getPageName, getQueryParams } from 'store/router/routerSelectors';
import { getTreatments } from 'store/split/splitSelectors';
import { useBreakpoints } from 'hooks/useBreakpoints';
import TreatmentToggle from 'components/TreatmentToggle';
import { searchSuggestionClick, searchSuggestionDisplay } from 'store/homePage/homePageActions';

const PLACEHOLDER_TEXT = 'Destination, city, hotel name';

const isLocation = (item) => item?.type === 'location';
const isProperty = (item) => item?.type === 'property';

const itemToString = (item) => item?.fullName ?? '';

const stateReducer = (state, actionAndChanges) => {
  const { type, changes } = actionAndChanges;

  switch (type) {
    case useCombobox.stateChangeTypes.InputBlur:
    case useCombobox.stateChangeTypes.InputKeyDownEscape:
      return { ...changes, inputValue: '' };
    default:
      return changes;
  }
};

const LocationAutocompleter = ({
  title,
  label,
  locationName,
  labelOptions,
  routeToProperty,
  updateQuery,
  placeholder,
  error,
  returnProperties,
  isOptional,
  limit,
}) => {
  const [items, setItems] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [openRecentSearches, setOpenRecentSearches] = useState(false);
  const dispatch = useDispatch();

  const recentSearches = getSearchLocalStorage();
  const isMobile = useBreakpoints().isLessThanBreakpoint(0);

  const pageName = useSelector(getPageName);
  const queryParams = useSelector(getQueryParams);
  const airbnbTab = queryParams?.searchType === 'airbnb';
  const isHotelsTab = pageName === 'home-page' && !airbnbTab;

  const splitTreatments = useSelector(getTreatments);
  const continueYourSearch = splitTreatments?.continue_your_search?.treatment === 'on';

  const truncateText = (text, limit) => {
    if (limit) return text?.length > limit ? text.substring(0, limit - 1) + '...' : text;
    return text;
  };
  /* Because we are combining render props with hooks, we need to store these functions as refs so they are defined in the hook and assigned in the render */
  const blurModalRef = useRef(noop);
  const openModalRef = useRef(noop);
  const inputRef = useRef();

  const onSelectedItemChange = useCallback(
    ({ selectedItem }) => {
      blurModalRef.current();
      inputRef.current.blur();
      setOpenRecentSearches(false);

      // Clear subregions when the location is changed - don't want to apply Melbourne subregions to a Brisbane location search
      if (isLocation(selectedItem)) updateQuery({ location: selectedItem.fullName, subRegions: undefined });
      if (isProperty(selectedItem))
        routeToProperty({ id: selectedItem.id, propertyName: selectedItem.fullName, excludeParams: ['location', 'propertyName'] });
    },
    [routeToProperty, updateQuery],
  );

  const { isOpen, highlightedIndex, getLabelProps, getInputProps, getMenuProps, getItemProps, inputValue, setInputValue } = useCombobox({
    items,
    itemToString,
    stateReducer,
    onSelectedItemChange,
    defaultHighlightedIndex: 0,
    inputId: 'location-search-input',
    labelId: 'location-search-label',
  });

  const results = useLocationData({ locationName: inputValue, returnProperties });
  useEffect(() => {
    setItems(results);
  }, [results]);

  const onFocus = useCallback(() => {
    openModalRef.current();
    setInputValue('');
    setIsFocused(true);
    setOpenRecentSearches(true);
  }, [setInputValue]);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const clearAllRecentSearches = useCallback(() => {
    clearSearchLocalStorage();
    setOpenRecentSearches(false);
  }, []);

  const recentSearchesCount = recentSearches?.length;
  const displayRecentSearches = recentSearchesCount > 0 && isHotelsTab && openRecentSearches && continueYourSearch && results.length === 0;

  const getRecentSearchProp = isMobile ? { isOpen: true } : { ...getMenuProps({ isOpen }) };

  const locations = recentSearches?.map((search) => search.location);

  const handleOnClick = (item, index) => {
    const isProperty = item.isPropertySearch;
    const componentVariant = isProperty ? 'property' : 'destination';

    dispatch(
      searchSuggestionClick({
        componentVariant: componentVariant,
        itemId: item.id,
        itemName: item.location,
        itemText: item.location,
        index: index,
        url: item.searchUrl,
        count: recentSearchesCount,
        searchRecent: locations,
        viewMode: 'list',
      }),
    );
  };

  useEffect(() => {
    if (displayRecentSearches) {
      dispatch(searchSuggestionDisplay());
    }
  }, [displayRecentSearches, dispatch]);

  return (
    <ResponsiveModal title={title} setOpenRecentSearches={setOpenRecentSearches}>
      {({ openModal, blurModal }) => {
        blurModalRef.current = blurModal;
        openModalRef.current = openModal;
        return (
          <Box position="relative">
            <Label {...getLabelProps()}>
              <LabelText {...labelOptions} hidden={[true, true, false]} color="greys.charcoal" fontWeight="normal" fontSize="sm">
                {label}
              </LabelText>
              <Box>
                <AutocompleteInput
                  data-testid="location-search-input"
                  error={error}
                  {...getInputProps({
                    ref: inputRef,
                    left: 2,
                    borderRadius: isOpen ? 'defaultRoundTopOnly' : 'default',
                    border: 2,
                    placeholder: isFocused ? placeholder : truncateText(locationName, limit) || placeholder,
                    icon: 'search',
                    isFocused,
                    onFocus,
                    onBlur,
                    suffix: isOptional ? 'Optional' : '',
                  })}
                />
              </Box>
            </Label>
            {displayRecentSearches && (
              <RecentSearchesWrapper data-testid="recent-searches-modal" {...getRecentSearchProp}>
                <TitleWrapper>
                  <TitleText>recent searches</TitleText>
                  <Link color="brand.primary" onClick={() => clearAllRecentSearches()} data-testid="clear-rc-btn">
                    <TitleText>clear {isMobile ? '' : 'all'}</TitleText>
                  </Link>
                </TitleWrapper>
                {recentSearches?.map((item, index) => (
                  <ResultItemRecentSearch key={index} onClick={() => handleOnClick(item, index)}>
                    <RecentSearch pastSearch={item} />
                  </ResultItemRecentSearch>
                ))}
              </RecentSearchesWrapper>
            )}

            <ResultList {...getMenuProps({ isOpen })}>
              {results.map((item, index) => (
                <ResultItem
                  key={index}
                  data-testid="location-search-result"
                  {...getItemProps({
                    index,
                    item,
                    highlighted: highlightedIndex === index,
                  })}
                >
                  <Box>
                    <Box>
                      {isLocation(item) && <Icon name="place" mr={2} ml="-8px" />}
                      {isProperty(item) && <Icon name="localHotel" mr={2} ml="-8px" />}
                      <TextHighlighter text={item.fullName} highlightText={inputValue} />
                    </Box>
                  </Box>
                  {item.hasLuxOffer && (
                    <TreatmentToggle split="luxury_inclusion_filter" treatment="on">
                      <Box>
                        <Badge
                          ml={6}
                          text={'Luxury Offer'}
                          size="xs"
                          color="white"
                          backgroundColor={'greys.charcoal'}
                          fontWeight="bold"
                          borderRadius="default"
                        />
                      </Box>
                    </TreatmentToggle>
                  )}
                </ResultItem>
              ))}
            </ResultList>
          </Box>
        );
      }}
    </ResponsiveModal>
  );
};

LocationAutocompleter.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  locationName: PropTypes.string,
  labelOptions: PropTypes.object,
  updateQuery: PropTypes.func.isRequired,
  routeToProperty: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  returnProperties: PropTypes.bool,
  isOptional: PropTypes.bool,
  limit: PropTypes.number,
  recentSearches: PropTypes.array,
};

LocationAutocompleter.defaultProps = {
  title: 'Find destination or hotel',
  label: 'Where would you like to go?',
  locationName: '',
  labelOptions: {},
  placeholder: PLACEHOLDER_TEXT,
  routeToProperty: () => {},
  error: false,
  returnProperties: true,
  isOptional: false,
  limit: null,
};

export default LocationAutocompleter;
