import styled from '@emotion/styled';
import { Box, Flex, Text } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { rem } from 'polished';
import { css } from '@emotion/core';
import { mediaQuery } from 'lib/styledSystem';
import InputWithAddon from 'components/InputWithAddon';

export const ResultList = styled(Box)`
  display: none;
  position: absolute;
  width: 100%;
  border-left: ${themeGet('borders.1')} ${themeGet('colors.greys.alto')};
  border-right: ${themeGet('borders.1')} ${themeGet('colors.greys.alto')};
  box-shadow: ${themeGet('shadows.result')};
  z-index: ${themeGet('zIndices.searchControls')};
  max-height: ${rem('150px')};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0); //fixes a safari bug with zindex clashing with sticky campaign messaging
  cursor: pointer;
  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
  ${mediaQuery.minWidth.sm} {
    max-height: ${rem('400px')};
  }
`;
ResultList.displayName = 'ResultList';

export const RecentSearchesWrapper = styled(Box)`
  display: none;
  position: absolute;
  margin-top: ${rem('10px')};
  z-index: ${themeGet('zIndices.phoneMenuDrawer')};
  width: 100%;
  background-color: ${themeGet('colors.white')};

  ${mediaQuery.minWidth.sm} {
    border: ${themeGet('borders.1')} ${themeGet('colors.greys.alto')};
    box-shadow: ${themeGet('shadows.result')};
    border-radius: ${themeGet('radii.default')};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`;

export const TitleWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 16px 18px 0px 18px;
  margin-top: ${themeGet('space.2')};
`;

export const TitleText = styled(Text)`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: ${themeGet('fontWeights.bold')};
`;

export const ResultItemRecentSearch = styled(Flex)`
  flex-direction: row;
  align-items: end;
  background-color: ${themeGet('colors.white')};
  padding: 16px 18px 16px 0px;
  cursor: pointer;
  z-index: ${themeGet('zIndices.phoneMenuDrawer')};

  &:hover {
    color: black;
    background-color: ${themeGet('colors.greys.porcelain')};
  }
`;
ResultItemRecentSearch.displayName = 'ResultItem';

export const ResultItem = styled(Flex)`
  flex-direction: column;
  align-items: start;
  background-color: ${themeGet('colors.greys.porcelain')};
  border-bottom: ${themeGet('borders.1')} ${themeGet('colors.greys.alto')};
  padding: ${themeGet('space.3')} ${themeGet('space.4')};
  cursor: pointer;
  ${(props) =>
    props.highlighted &&
    css`
      background-color: white;
    `};
`;
ResultItem.displayName = 'ResultItem';

export const AutocompleteInput = styled(InputWithAddon)(
  (props) =>
    !props.isFocused &&
    props.placeholder.includes('Enter a destination') && {
      '::placeholder': {
        opacity: 1, // Firefox's default placeholder opacity is less that 1
        color: props.theme.colors.greys.steel,
      },
    },
  (props) =>
    !props.isFocused &&
    !props.placeholder.includes('Enter a destination') && {
      '::placeholder': {
        opacity: 1, // Firefox's default placeholder opacity is less that 1
        color: props.theme.colors.greys.charcoal,
      },
    },
);

AutocompleteInput.displayName = 'AutocompleteInput';
