import React from 'react';
import PropTypes from 'prop-types';
import { SubText } from './style.RecentSearch';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const deconstructDate = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const monthNumber = newDate.getMonth();
  const month = monthNames[monthNumber];
  const year = newDate.getFullYear();
  const decoDate = {
    day: day,
    month: month,
    year: year,
  };

  return decoDate;
};

export const formatDates = (checkIn, checkOut) => {
  const allEqual = (arr) => arr.every((v) => v === arr[0]);
  const { day: checkInDate, month: checkInMonth, year: checkInYear } = deconstructDate(checkIn);
  const { day: checkOutDate, month: checkOutMonth, year: checkOutYear } = deconstructDate(checkOut);
  const years = [checkInYear, checkOutYear];
  const months = [checkInMonth, checkOutMonth];
  const days = [checkInDate, checkOutDate];

  if (allEqual(months)) {
    return days.join('-') + ' ' + checkInMonth + ' ' + checkInYear;
  } else if (allEqual(years)) {
    const date = checkInDate + ' ' + checkInMonth + ' - ' + checkOutDate + ' ' + checkOutMonth + ' ' + checkOutYear;
    return date;
  } else {
    const date = checkInDate + ' ' + checkInMonth + ' ' + checkInYear + ' - ' + checkOutDate + ' ' + checkOutMonth + ' ' + checkOutYear;
    return date;
  }
};

export const SearchParams = ({ title = '', value, addComma = 'false' }) => {
  return (
    <SubText data-testid={title}>
      {addComma && <>, </>}
      {value} {title}
    </SubText>
  );
};

SearchParams.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  addComma: PropTypes.bool,
};
