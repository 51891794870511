import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon } from '@qga/roo-ui/components';
import { formatDates, SearchParams } from './helper';
import { Wrapper, SearchLink, IconBox } from './style.RecentSearch';

const RecentSearch = ({ pastSearch }) => {
  const { location, checkIn, checkOut, adults, children, infants, searchUrl } = pastSearch;
  const dateRange = formatDates(checkIn, checkOut);
  const recentSearchUrl = searchUrl + '&isRecentSearch=true';

  const adultText = adults > 1 ? 'Adults' : 'Adult';
  const childrenText = children > 1 ? 'Children' : 'Child';
  const infantsText = infants > 1 ? 'Infants' : 'Infant';

  return (
    <SearchLink href={recentSearchUrl}>
      <Wrapper>
        <IconBox>
          <Icon name="history" size={20} />
        </IconBox>
        <Flex flexDirection={'column'} fontSize="sm">
          {location}
          <Flex>
            <SearchParams value={dateRange} addComma={false} />
            <SearchParams title={adultText} value={adults} addComma />
            {children > 0 && <SearchParams title={childrenText} value={children} addComma />}
            {infants > 0 && <SearchParams title={infantsText} value={infants} addComma />}
          </Flex>
        </Flex>
      </Wrapper>
    </SearchLink>
  );
};

RecentSearch.propTypes = {
  pastSearch: PropTypes.object,
};

export default RecentSearch;
