import { Flex, Link, Text, Box } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

export const SearchLink = styled(Link)`
  width: 100%;
  color: black;
  &:hover {
    color: black;
  }
  &:focus {
    color: black;
  }
`;

export const Wrapper = styled(Flex)`
  align-items: flex-start;
  justify-content: flex-start;
`;

export const IconBox = styled(Box)`
  line-height: 30px;
  background-color: ${themeGet('colors.greys.whisper')};
  border-radius: ${themeGet('radii.default')};
  padding: 0px 5px;
  margin: 0 ${themeGet('space.3')} 0 ${themeGet('space.4')};
`;

export const SubText = styled(Text)`
  color: ${themeGet('colors.greys.dusty')};
  font-size: 14px;
`;
